/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ChangePasswordPage } from "../ChangePassword/ChangePasswordPage";
import * as actions from "./redux/activationAction"
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { toastOption } from "../../../_metronic/_helpers";
import { toast } from "react-toastify"


export function ActivationPage({ history, match }) {

  const [inputValue, setInputValue] = useState({
    email: "",
    birth_date: "",
    otp: "",
  })

  const { currentState } = useSelector(
    (state) => ({ currentState: state.activation }),
    shallowEqual,
  );

  // console.log(inputValue);

  const dispatch = useDispatch()

  const [conditionTo, setConditionTo] = useState({
    otp: false,
    changePass: false
  })

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email / No Handphone wajib diisi")
  })


  const sendOtpCode = () => {
    dispatch(actions.sendOtp(inputValue.email)).then(res => {
      res && res.status === 200 &&
        setConditionTo(prevState => ({
          ...prevState,
          otp: true,
        }))
    }).catch(err => {
      setConditionTo(prevState => ({
        ...prevState,
        otp: false,
      }))
    })
  }

  const verifyOtp = () => {
    if (!inputValue.email || !inputValue.otp || !inputValue.birth_date) toast.error("Mohon lengkapi data kembali", toastOption);
    dispatch(actions.verifyOtp(inputValue)).then(res => {
      res && res.status === 200 &&
        setConditionTo(prevState => ({
          ...prevState,
          otp: false,
          changePass: true,
        }))
    }).catch(err => {
      console.log(err);
      if (err) toast.error("Kode otp tidak cocok", toastOption)
      setConditionTo(prevState => ({
        ...prevState,
        changePass: false,
      }))
    })
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Portal Donatur
                </h3>
                {/* <p className="font-weight-lighter text-white opacity-80">
                  <i>Pelayanan Mandiri Dompetdhuafa</i>
                </p> */}
                <p className="font-weight-lighter text-white opacity-80">
                  <i>Portal Layanan Mandiri Donatur Dompet Dhuafa</i>
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; 2021 Dompet Dhuafa
                </div>

              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center">
              <div className="login-form login-signin" id="kt_login_signin_form">
                {/* begin::Head */}

                <div className="mb-10">
                  {conditionTo.changePass != true ? (
                    <h3 className="text-center font-size-h1">
                      Aktivasi Akun
                    </h3>
                  ) : (
                    <h3 className="text-center font-size-h1">
                      Ganti Password
                    </h3>
                  )}
                  {conditionTo.otp && (
                    <div className="alert alert-custom alert-light-info">
                      <div className="alert-text font-weight-bold">Kode OTP terkirim! Silahkan periksa Email / Handphone anda</div>
                    </div>
                  )}
                </div>
                {/* end::Head */}

                {/*begin::Form*/}
                {conditionTo.changePass !== true ? (

                  <Formik
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                    enableReinitialize={true}
                    initialValues={{}}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      sendOtpCode(values);
                    }}
                  >
                    {({ handleSubmit, setFieldValue, values }) => (
                      <>
                        {conditionTo.otp === false ? (
                          <>
                            <div className="form-group fv-plugins-icon-container">
                              <Field
                                placeholder="Email : email@gmail.com / HP : 0866xxxxxx"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="email"
                                value={inputValue.email}
                                onChange={e => {
                                  const value = e.target.value
                                  setFieldValue("email", e.target.value)
                                  setInputValue(prevState => ({
                                    ...prevState,
                                    email: value
                                  }))
                                }}
                              />
                              <ErrorMessage
                                name="email"
                                render={(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              />
                              <small>
                                <p className="text-center mt-1">
                                  Aktivasi akun Anda menggunakan Email atau Nomor Hp yang pernah anda daftarkan atau anda gunakan untuk Donasi.
                                  <br />
                                  Contoh untuk Email email@gmail.com atau untuk Nomor Hp 0866xxxx</p>
                                {/* <br /> */}
                                <p className="mt-0"></p>
                              </small>
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

                              <div className="my-3 mr-2"></div>

                              <button
                                id="kt_login_signin_submit"
                                type="submit"
                                disabled={currentState.actionsLoading && true}
                                onClick={() => {
                                  sendOtpCode()
                                }}
                                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                              >
                                <span>Next</span>
                                {currentState.actionsLoading && <span className="ml-3 spinner spinner-white"></span>}
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="form-group fv-plugins-icon-container">
                              <Field
                                placeholder="Masukkan kode OTP"
                                type="number"
                                value={inputValue.otp}
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="otp"
                                onChange={e => {
                                  setFieldValue("otp", e.target.value)
                                  setInputValue(prevState => ({
                                    ...prevState,
                                    otp: e && e.target && e.target.value
                                  }))
                                }}
                              />
                              <ErrorMessage
                                name="otp"
                                render={(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              />
                            </div>

                            <div className="form-group fv-plugins-icon-container">
                              <label>Masukkan Tanggal Lahir</label>
                              <Field
                                placeholder="Masukkan Tanggal Lahir"
                                type="date"
                                value={inputValue.birth_date}
                                className={`form-control form-control-solid h-auto py-5 px-6`}
                                name="birth_date"
                                onChange={e => {
                                  setFieldValue("birth_date", e.target.value)
                                  setInputValue(prevState => ({
                                    ...prevState,
                                    birth_date: e && e.target && e.target.value
                                  }))
                                }}
                              />
                              <ErrorMessage
                                name="birth_date"
                                render={(msg) => (
                                  <div style={{ color: "red" }}>{msg}</div>
                                )}
                              />
                            </div>
                            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">

                              <div className="my-3 mr-2"></div>

                              <button
                                id="kt_login_signin_submit"
                                type="submit"
                                onClick={() => {
                                  verifyOtp()
                                }}
                                disabled={currentState.actionsLoading && true}
                                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                              >
                                <span>Verifikasi</span>
                                {currentState.actionsLoading && <span className="ml-3 spinner spinner-white"></span>}
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Formik>
                ) : (
                  <ChangePasswordPage user={inputValue.email} history={history} />
                )}
                {/*end::Form*/}
              </div>
            </div>
            <div className="" style={{
              // backgroundColor: "#5926A5",
              // backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-4.jpg")})`
            }}>
              <p className="text-lg-right text-center font-weight-lighter opacity-80 p-7">Mengalami kendala ?<br />
                Hubungi Customer Care Dompet Dhuafa<br />
                Call center: 0804-100-4000 / (021) 508 66860 WA center: +62811-1544-488<br /></p>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2021 Dompet Dhuafa
              </div>

            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
